import React from "react";
import { ImageComponent } from "./FallbackImage";

export default function NoData() {
  return (
    <div className="flex justify-center py-5">
      <div className="flex-col items-center justify-center">
        <ImageComponent src="/icon/circlewithslashgreen.svg" />
        <div className="text-[#282728] text-[22px] font-medium">
          No Data Found!
        </div>
      </div>
    </div>
  );
}
