import React, { useState } from "react";
import moment from "moment";
import ButtonComp from "../../../Common/ButtonComp";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import ReportsDownload from "../../../Common/ReportsDownload";

const CustomerReports = ({ id, activity, payment, order, debt }) => {
  const initialReportState = [
    {
      name: "Activity Report",
      startDate: null,
      endDate: null,
      data: activity?.data?.reports ?? [],
    },
    {
      name: "Payment Report",
      startDate: null,
      endDate: null,
      data: payment?.data?.reports ?? [],
    },
    {
      name: "Order Report",
      startDate: null,
      endDate: null,
      data: order?.data?.orders ?? [],
    },
    {
      name: "Debt Report",
      startDate: null,
      endDate: null,
      data: debt?.data?.reports ?? [],
    },
  ];

  const [reports, setReports] = useState(initialReportState);
  const [error, setError] = useState(null);

  const handleDateChange = (index, dateType, date) => {
    const updatedReports = reports.map((report, idx) => {
      if (idx === index) {
        return { ...report, [dateType]: date };
      }
      return report;
    });
    setReports(updatedReports);
    setError(null); // Reset error when dates change
  };

  const filterDataByDate = (data, startDate, endDate) => {
    if (!startDate || !endDate) {
      return data;
    }

    const start = moment(startDate).startOf("day");
    const end = moment(endDate).endOf("day");

    return data.filter((item) => {
      const date = moment(item.createdAt); // Ensure the date field is correct
      return date.isBetween(start, end, null, "[]");
    });
  };

  const flattenObject = (obj) => {
    const flattened = {};
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        flattened[key] = JSON.stringify(obj[key]);
      } else {
        flattened[key] = obj[key];
      }
    }
    return flattened;
  };

  return (
    <div className="item_container pb-[6rem] flex items-start justify-center flex-col gap-[2rem]">
      {reports.map((item, index) => {
        const filteredData = filterDataByDate(
          item?.data ?? [],
          item?.startDate,
          item?.endDate
        ).map(flattenObject);

        return (
          <ReportsDownload
            name={item.name}
            index={index}
            startDate={item?.startDate}
            endDate={item?.endDate}
            filteredData={filteredData}
            handleDateChange={handleDateChange}
          />
        );
      })}
    </div>
  );
};

export default CustomerReports;
