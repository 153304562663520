import React from "react";
import { ImageComponent } from "./FallbackImage";
import ButtonComp from "../Ui/Button";

export default function DeleteModal({
  setShowModal,
  loader,
  handleSubmit,
  title,
}) {
  return (
    <form>
      <div className="flex justify-center mb-[20px]">
        <ImageComponent src="/icon/box.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[20px] mb-[20px] mx-[30px] lg:mx-[72px] leading-[32px] ">
          {title}
        </h5>
      </div>
      <div className="row  mb-[64px] gap-[15px] justify-center">
        <div className="col-5">
          <ButtonComp
            text={"Cancel"}
            onClick={setShowModal}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full  py-[5px] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
            }
          />
        </div>
        <div className="col-5">
          <ButtonComp
            loader={loader}
            type="submit"
            onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
            }
            text={"Proceed"}
          />
        </div>
      </div>
    </form>
  );
}
