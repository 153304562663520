import React, { useEffect, useState } from "react";
import { ImageComponent } from "../../Common/FallbackImage";

import { toast } from "react-toastify";
import ButtonComp from "../../Ui/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { tabTop } from "./Data";

export default function ShowSchedularComp() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tab, setTab] = useState("Activities");

  return (
    <div>
      <div className="flex flex-wrap justify-between items-center mb-5">
        <h4
          className="flex gap-2 items-center font-semibold"
          onClick={() => navigate("/product")}
        >
          <ImageComponent src="/icon/back.svg" className="cursor-pointer" />
          Chigozie Igolo
        </h4>
        <div className="flex gap-2 items-center ">
          <ButtonComp
            btnClassName={
              "border-[#4F008A] w-[100%] border-[1px] rounded-pill flex-1 px-3 px-lg-4 py-2 py-lg-2"
            }
            text={
              <span className="flex gap-[5px] items-center  text-[14px]">
                <ImageComponent
                  src="/icon/downloadI.svg"
                  width={18}
                  height={18}
                />
                Report
              </span>
            }
          />
          <ButtonComp
            // onClick={()=>setProductCsv(true)}
            btnClassName={"px-4 py-[10px] rounded-pill bg-[#3DA84A] text-white"}
            text={<span className="flex gap-2 "> Track Location</span>}
          />
        </div>
      </div>

      <div className="row border-[1px] border-[#EDE6F3] rounded-sm px-2 py-3 items-center justify-between mb-5">
        <div className="col-lg-2 flex justify-center lg:justify-start">
          <ImageComponent src="/Images/noImage.png" height={180} />
        </div>
        <div className="col-lg-3 ">
          <div className="flex-col justify-between items-stretch">
            <div className="flex gap-1 items-center text-[20px] text-[#292D32] font-semibold mb-3">
              Chigozie Igolo
              <ImageComponent src="/icon/star.svg" height={12} />
              <div className="text-[16px] font-light">5.0</div>
            </div>
            <div className="text-[#757575] font-medium mb-3">
              Field Rep <span className="text-[#000000]">-</span>{" "}
              <span className="text-[#044E54]">Active</span>
            </div>
            <div>
              <ButtonComp
                text={"Send A Report"}
                btnClassName={
                  "w-100 border-[#D634341A] border-[1px] py-2 text-[#D63434] font-medium rounded-full"
                }
              />
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="mb-3">
            <div className="mb-2 text-[#555555] font-medium">Location</div>
            <div className="text-[#000000] font-medium">Ikeja, Lagos State</div>
          </div>
          <div>
            <div className="mb-2 text-[#555555] font-medium">Date Added</div>
            <div className="text-[#000000] font-medium">24th Jan, 2023</div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="mb-3">
            <div className="mb-2 text-[#555555] font-medium">Phone Number</div>
            <div className="text-[#000000] font-medium">+2348129960536</div>
          </div>
          <div>
            <div className="mb-2 text-[#555555] font-medium">Email Address</div>
            <div className="text-[#000000] font-medium">
              olakunbiolabode01@gmail.com
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="flex gap-3 border-b-[#4F008A1A] border-b-[1px] mb-5 ">
          {tabTop?.map((item, i) => (
            <div
              onClick={() => setTab(item)}
              className={`${
                tab === item && "border-b-[#4F008A] border-b-[3px]"
              }  pb-2 cursor-pointer`}
              key={i}
            >
              {item}
            </div>
          ))}
        </div>

        <div>
          <div className="flex gap-4 items-center mb-3">
            <div>
              <ImageComponent src="/icon/delivery.svg" />
            </div>
            <div>
              <div className="text-[#757575] text-[14px]">
                9:00am - 12th March, 2023
              </div>
              <div className="text-[#292D32] text-[14px]">
                Drug Delivery to His Grace Pharmacy at Gbagada Express, Lagos
              </div>
            </div>
          </div>

          {[1, 2, 4]?.map((item, i) => (
            <div className="flex gap-4 items-center mb-3" key={i}>
              <div>
                <ImageComponent src="/icon/payment.svg" />
              </div>
              <div>
                <div className="text-[#757575] text-[14px]">
                  9:00am - 12th March, 2023
                </div>
                <div className="text-[#292D32] text-[14px]">
                  Payment of #20,000.00 for Paracetamol drug ordered by His
                  Grace Pharmacy
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
