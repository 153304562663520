import React, { useState, useEffect } from "react";
import {
  useGetAllLeadsQuery,
  useGetFieldRepLeadsQuery,
} from "../../../../store/Customer/CustomerApi";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const Leads = ({ id }) => {
  const { data: getLeads, isLoading, isSuccess } = useGetFieldRepLeadsQuery(id);

  return (
    <section>
      <div className="leads_container">
        {isLoading ? (
          <div className="spinner_container">
            <Spinner size="sm" />
          </div>
        ) : isSuccess && getLeads?.data?.length > 0 ? (
          <div className="flex flex-col w-full">
            {getLeads?.data
              ?.slice()
              .reverse()
              .map((item) => (
                <div
                  className="item_container  flex items-center justify-start mb-[2rem] gap-[1rem]"
                  key={item?.id}
                >
                  <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                    <img src="/svgs/visit.svg" alt="" />
                  </div>

                  <div className="text_container  w-full flex flex-col items-start justify-between h-full">
                    <small className="block text-[.75rem] text-gray-400">
                      {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                    </small>
                    <span className="block text-[.8rem] font-[500] text-black">
                      {`Created ${item?.name} of ${item?.company?.name} with status of  ${item?.status}`}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
            <img
              src="/svgs/noSchedule.svg"
              alt=""
              className="w-[5rem] h-[5rem]"
            />
            <span className="block">No Data</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default Leads;
