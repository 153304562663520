import React, { useState, useEffect } from "react";
import {
  useGetAllFieldRepActivitiesQuery,
  useGetAllFieldRepReportsQuery,
  useGetAllReportsQuery,
  useGetScheduleQuery,
} from "../../../../store/Customer/CustomerApi";
import { currentDate, firstDateOfTheMonth } from "../../../../utils/helper";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { formatMoney } from "../../../../utils/formatMoney";

// Leads, Schedule, report

const Activities = ({ id }) => {
  const {
    data: allFieldRepReports,
    isLoading,
    isSuccess,
  } = useGetAllFieldRepReportsQuery(id);

  console.log(allFieldRepReports, "field rep reports");

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <div className="schedule">
      <div className="item_container">
        {allFieldRepReports?.data?.reports.length === 0 ? (
          <div className="item_container">
            <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
              <img
                src="/svgs/noSchedule.svg"
                alt=""
                className="w-[5rem] h-[5rem]"
              />
              <span className="block">No Data</span>
            </div>
          </div>
        ) : (
          allFieldRepReports?.data?.reports
            ?.slice()
            .reverse()
            .filter((item) => item?.reportType) // Filter out items without a reportType
            .map((item) => (
              <div
                className="item_container flex items-center justify-start mb-[2rem] gap-[1rem]"
                key={item.id}
              >
                {item?.reportType && (
                  <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                    <img
                      src={`/svgs/${
                        item?.reportType === "debtor"
                          ? "redpayment"
                          : item?.reportType === "payment"
                          ? "payment"
                          : item?.reportType === "userActivities" ||
                            item?.reportType === "customerActivities"
                          ? "visit"
                          : "visit"
                      }.svg`}
                      alt=""
                      className="w-[2rem] h-[2rem]"
                    />
                  </div>
                )}
                <div className="text_container w-full flex flex-col items-start justify-between h-full">
                  <small className="block text-[.75rem] text-gray-400">
                    {item?.reportType &&
                      moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                  </small>
                  <span className="block text-[.8rem] font-[500] text-black">
                    {item?.reportType === "debtor" ? (
                      item?.paymentStatus === "Paid" ? (
                        <div className="item_container">
                          {`Debt payment of ${formatMoney(
                            item?.payment?.amount
                          )} by ${item?.customer?.businessName} via ${
                            item?.paymentMethod
                          } with status of ${item?.paymentStatus}`}
                        </div>
                      ) : (
                        <div className="item_container">
                          {`Owing debt of ${formatMoney(
                            item?.balanceAmount
                          )} by ${item?.customer?.contactPersonName} of ${
                            item.customer?.businessName
                          } with status of ${item?.paymentStatus}`}
                        </div>
                      )
                    ) : item?.reportType === "payment" ? (
                      <p className="description text-[.8rem] text-[#1c1c1c]">
                        {item?.amount
                          ? `${item?.paymentMethod} payment of ${formatMoney(
                              item?.amount
                            )} made by ${item?.customer?.businessName}  ${
                              item?.balanceAmount
                                ? `with balance of ${formatMoney(
                                    item?.balanceAmount
                                  )}`
                                : ""
                            } at ${item?.customer?.address} with status of ${
                              item?.paymentStatus
                            }`
                          : `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`}
                      </p>
                    ) : item?.reportType === "userActivities" ? (
                      item?.reportMessage
                    ) : item?.reportType === "customerActivities" ? (
                      item?.reportMessage ||
                      `${item?.paymentMethod} of ${formatMoney(
                        item?.amount
                      )} with status of ${item?.paymentStatus}`
                    ) : null}
                  </span>
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default Activities;
