import React, { useEffect, useState } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import {
  useGetLocationsQuery,
  useGetRepLocationQuery,
  useGetScheduledVisitQuery,
} from "../../../store/Customer/CustomerApi";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import moment from "moment";

const ShowMapComp = () => {
  const { customerId, id } = useParams();
  const google = window.google;

  const navigate = useNavigate();

  const [close, setClose] = useState(false);

  const scheduleList = [
    {
      title: "Drug delivery to His Grace Pharmacy",
      time: "9:00am",
      status: "Pending",
    },
    {
      title: "Drug delivery to His Grace Pharmacy",
      time: "9:00am",
      status: "On Route",
    },
    {
      title: "Drug delivery to His Grace Pharmacy",
      time: "11:00am",
      status: "Completed",
    },
  ];

  const {
    data: DataMap,
    isLoading,
    isSuccess,
  } = useGetScheduledVisitQuery({ id: customerId });

  console.log(DataMap, "Data");

  const [mapState, setMapState] = useState({
    center: {
      lat: Number(DataMap?.data[0]?.checkIns[0]?.latitude),
      lng: Number(DataMap?.data[0]?.checkIns[0]?.longitude),
    },
    zoom: 13,
  });

  useEffect(() => {
    setMapState({
      center: {
        lat: Number(DataMap?.data[0]?.checkIns[0]?.latitude),
        lng: Number(DataMap?.data[0]?.checkIns[0]?.longitude),
      },
      zoom: 13,
    });
  }, [isSuccess]);

  const onPlaceSelected = (place) => {
    setMapState({
      center: place.geometry.location,
      zoom: 18,
    });
  };

  const Auto = (props) => (
    <Autocomplete
      style={{ width: "90%" }}
      onPlaceSelected={(place) => {
        // console.log(place);
        onPlaceSelected(place);
      }}
      types={["(regions)"]}
    />
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <Spinner size="md" />
      </div>
    );
  }

  if (isSuccess && DataMap?.data?.length === 0) {
    return (
      <div className="flex items-center justify-center h-[100vh]">
        <div>No items on map to display</div>
      </div>
    );
  }

  const GoogleMapExample = withGoogleMap((props) => (
    <GoogleMap center={mapState.center} zoom={mapState.zoom}>
      {/* {DataMap?.data?.map((item, i) => (
        <Marker
          position={{
            lat: Number(item?.latitude),
            lng: Number(item?.longitude),
          }}
        />
      ))} */}
      {
        <Marker
          position={{
            lat: Number(DataMap?.data[0]?.checkIns[0]?.latitude),
            lng: Number(DataMap?.data[0]?.checkIns[0]?.longitude),
          }}
        />
      }
    </GoogleMap>
  ));

  console.log(DataMap, "DataMap");

  return (
    <div className="">
      <div style={{}}>
        {/* <h3>Choose another destination</h3> */}
        <Auto />
      </div>

      <div className="relative">
        <GoogleMapExample
          containerElement={<div style={{ height: `90vh`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />

        <div
          className={`absolute top-0 left-0 w-full h-full bg-black opacity-50 pointer-events-none ${
            close ? "hidden" : "block"
          }`}
          style={{ zIndex: 1 }}
        />

        <div
          className={`item_container z-10 rounded-md bg-white min-w-[30rem] p-3 min-h-[30rem] absolute top-[20%] left-[5%] ${
            close ? "hidden" : "block"
          }`}
        >
          <div className="rep_dets flex items-start justify-between w-full">
            <div className="left_container flex items-center justify-center gap-4">
              <div className="image_container w-[8rem] h-[7rem] rounded-[8px] overflow-hidden">
                <img
                  src={"/svgs/noimage.jpg"}
                  alt="rep-image"
                  className="w-full h-full"
                />
              </div>
              <div className="text_container">
                <h1 className="font-semibold text-black text-[1.4rem]">
                  {DataMap?.data[0]?.user?.firstName}{" "}
                  {DataMap?.data[0]?.user?.lastName}
                </h1>
                <div className="location_container flex items-center justify-start gap-2">
                  <img src="/svgs/location.svg" alt="location-icon" />
                  <span className="location-name text-[.9rem] text-gray-500">
                    {DataMap?.data[0]?.user?.fullAddress || "-"}
                  </span>
                </div>
              </div>
            </div>
            <button className="cancel-icon mt-3" onClick={() => setClose(true)}>
              <img src="/svgs/cancelgray.svg" alt="cancel-icon" />
            </button>
          </div>

          <div className="line w-full h-[.01rem] bg-gray-200 my-3"></div>
          <div className="schedule_details">
            <div className="title flex items-center justify-between">
              <h1 className="title font-semibold text-[1.2rem]">Schedule</h1>
              <select
                name="schedule-list"
                id=""
                className="w-[8rem] px-2 h-[2rem] rounded-full border-solid border-[1px] border-blue-950"
              >
                <option value="Today" className="font-semibold">
                  Today
                </option>
              </select>
            </div>

            <div className="schedule_list">
              {scheduleList.length === 0 ? (
                <div className="item_container mt-3 flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
                  <img
                    src="/svgs/noSchedule.svg"
                    alt=""
                    className="w-[5rem] h-[5rem]"
                  />
                  <span className="block">No Schedule Yet</span>
                  <button
                    onClick={() => navigate("/schedule")}
                    className="mt-3 h-[3rem] min-w-[13rem] px-3 text-[.9rem] rounded-full text-white bg-[#3DA84A] flex items-center justify-center text-center"
                  >
                    Create New Schedule
                  </button>
                </div>
              ) : (
                <div className="item_container mt-4">
                  {DataMap?.data.map((item) => (
                    <div className="items_container mb-3" key={item.id}>
                      <div className="time_status flex items-center justify-between">
                        <small className="time text-gray-400">
                          {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                        </small>
                        <small
                          className={`status ${
                            item.status === "PENDING"
                              ? "text-[#005FFF]"
                              : item.status === "COMPLETED"
                              ? "text-[#044E54]"
                              : "text-[#8C8C36]"
                          }`}
                        >
                          {item?.status}
                        </small>
                      </div>
                      <div className="item_desc mt-1">
                        <span className="text-black text-[.9rem] font-normal">
                          {item?.visitationReason === "Orders" ? (
                            <div className="text-container">{`Order placed with Status of ${item.status} and checkin status of ${item.checkIns[0]?.status}`}</div>
                          ) : (
                            <div className="text-container"></div>
                          )}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowMapComp;
