import AWS from "aws-sdk";
import { toast } from "react-toastify";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: "eu-west-2",
});

const s3 = new AWS.S3();

export const uploadFileToS3 = async (file, bucketName, folderName) => {
  const params = {
    Bucket: "suppliers-product-img",
    Key: `${folderName}/${file.name}`,
    Body: file,
    ContentType: file.type,
    ACL: "public-read", // Adjust the ACL as needed
  };

  try {
    const data = await s3.upload(params).promise();
    return data.Location;
    // The URL of the uploaded file

    console.log(data.Location, "URL that we need");
  } catch (error) {
    toast.error(error?.message);
    throw new Error("File upload failed:", error?.message);
    console.log(error?.message);
  }
};
