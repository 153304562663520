import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import SalesOrderComp from "../../Components/modules/Sales Orders";

export default function SalesOrder() {
  return (
    <AdminLayout active={3}>
      <SalesOrderComp />
    </AdminLayout>
  );
}
